var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',[_c('label',{attrs:{"for":"huolenaihe"}},[_vm._v(_vm._s(_vm.$t('reportForm.subject')))]),_c('v-text-field',{staticClass:"pt-0",attrs:{"id":"huolenaihe","background-color":"#EDEDEF","solo":"","flat":"","hide-details":"","rules":_vm.inputRules},on:{"input":_vm.setFormData},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(_vm._s(_vm.$t(message)))]}}]),model:{value:(_vm.whatHappened),callback:function ($$v) {_vm.whatHappened=$$v},expression:"whatHappened"}})],1)],1),_c('v-row',[_c('v-col',[_c('label',{attrs:{"for":"huolenaihe"}},[_vm._v(_vm._s(_vm.$t('reportForm.time')))]),_c('v-text-field',{staticClass:"pt-0",attrs:{"id":"huolenaihe","background-color":"#EDEDEF","solo":"","flat":"","hide-details":"","rules":_vm.inputRules},on:{"input":_vm.setFormData},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(_vm._s(_vm.$t(message)))]}}]),model:{value:(_vm.whenHappened),callback:function ($$v) {_vm.whenHappened=$$v},expression:"whenHappened"}})],1)],1),_c('v-row',[_c('v-col',[_c('label',{attrs:{"for":"huolenaihe"}},[_vm._v(_vm._s(_vm.$t('reportForm.place')))]),_c('v-text-field',{staticClass:"pt-0",attrs:{"id":"huolenaihe","background-color":"#EDEDEF","solo":"","flat":"","hide-details":"","rules":_vm.inputRules},on:{"input":_vm.setFormData},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(_vm._s(_vm.$t(message)))]}}]),model:{value:(_vm.whereHappened),callback:function ($$v) {_vm.whereHappened=$$v},expression:"whereHappened"}})],1)],1),_c('v-row',[_c('v-col',[_c('label',{attrs:{"for":"huolenaihe"}},[_vm._v(_vm._s(_vm.$t('reportForm.details')))]),_c('v-textarea',{staticClass:"pt-0",attrs:{"id":"huolenaihe","background-color":"#EDEDEF","solo":"","flat":"","hide-details":"","rules":_vm.inputRules},on:{"input":_vm.setFormData},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(_vm._s(_vm.$t(message)))]}}]),model:{value:(_vm.moreInformation),callback:function ($$v) {_vm.moreInformation=$$v},expression:"moreInformation"}})],1)],1)],1),_c('v-btn',{staticClass:"my-8",staticStyle:{"color":"#fff","font-size":"1rem","position":"absolute","right":"12px"},attrs:{"disabled":!this.valid,"to":("/" + (this.$store.state.organization.urlName) + "/yhteystiedot"),"depressed":"","color":"#424752"}},[_vm._v(" "+_vm._s(_vm.$t('general.next'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }