<template>
<div>
    <v-form v-model="valid">
            <v-row>
                <v-col>
                    <label for="huolenaihe">{{ $t('reportForm.subject')}}</label>
                    <v-text-field
                        id="huolenaihe"
                        class="pt-0"
                        v-model="whatHappened"
                        background-color="#EDEDEF"
                        solo
                        flat
                        hide-details
                        :rules="inputRules"
                        @input="setFormData">
                        <template #message="{ message }">{{ $t(message) }}</template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <label for="huolenaihe">{{ $t('reportForm.time')}}</label>
                    <v-text-field
                        id="huolenaihe"
                        class="pt-0"
                        v-model="whenHappened"
                        background-color="#EDEDEF"
                        solo
                        flat
                        hide-details
                        :rules="inputRules"
                        @input="setFormData">
                        <template #message="{ message }">{{ $t(message) }}</template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <label for="huolenaihe">{{ $t('reportForm.place')}}</label>
                    <v-text-field
                        id="huolenaihe"
                        class="pt-0"
                        v-model="whereHappened"
                        background-color="#EDEDEF"
                        solo
                        flat
                        hide-details
                        :rules="inputRules"
                        @input="setFormData">
                        <template #message="{ message }">{{ $t(message) }}</template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <label for="huolenaihe">{{ $t('reportForm.details')}}</label>
                    <v-textarea
                        id="huolenaihe"
                        class="pt-0"
                        v-model="moreInformation"
                        background-color="#EDEDEF"
                        solo
                        flat
                        hide-details
                        :rules="inputRules"
                        @input="setFormData">
                        <template #message="{ message }">{{ $t(message) }}</template>
                    </v-textarea>
                </v-col>
            </v-row>
    </v-form>
    <v-btn
        :disabled="!this.valid"
        :to="`/${this.$store.state.organization.urlName}/yhteystiedot`"
        depressed
        color="#424752"
        style="color: #fff; font-size: 1rem;position: absolute; right: 12px;"
        class="my-8">
            {{ $t('general.next')}}
        </v-btn>
    </div>
</template>

<style scoped>

label {
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
}

</style>

<script>
export default {
    data: function () {
        return {
            valid: false,
            inputRules: [
                v => !!v || 'contactInfo.emptyFieldAlert'
            ],
            whatHappened: this.$store.state.whatHappened,
            whenHappened: this.$store.state.whenHappened,
            whereHappened: this.$store.state.whereHappened,
            moreInformation: this.$store.state.moreInformation,
            organisation: {
                name: this.$route.params.report
            }
        }
  },
    methods: {
      setFormData: function() {
        this.$store.commit("updateReport",
            {
                whatHappened: this.whatHappened, 
                whenHappened: this.whenHappened, 
                whereHappened: this.whereHappened, 
                moreInformation: this.moreInformation
            }
        );
      }
    }
}
</script>
